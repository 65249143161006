import market from '../../api/market';
import getCurrentListings from '../selectors/get-current-listings';
import { getDefaultQuery, getSearchQuery } from '../selectors/get-search-query';

const updateSearch = (query, listings, append, dispatch) => {
  dispatch({
    type: 'SET_QUERY',
    payload: query,
  });

  const isLastPage = listings.length < query.limit;
  dispatch({
    type: append ? 'APPEND_LISTINGS' : 'SET_LISTINGS',
    payload: {
      listings,
      isLastPage,
    },
  });
};

export const searchListing = (query = {}) => async (dispatch) => {
  const defaultQuery = getDefaultQuery();
  const mergedQuery = { ...defaultQuery, ...query }
  const listings = await market.search(mergedQuery);
  updateSearch(mergedQuery, listings, false, dispatch);
};

export const scrollListings = () => async (dispatch, getState) => {
  // Calculate the cursor values by taking the last row's id.
  const existingListings = getCurrentListings(getState()).listings;
  const lastId = existingListings.at(-1).id;
  const query = { ...getSearchQuery(getState()) };
  query.offset = lastId;
  const listings = await market.search(query);
  updateSearch(query, listings, true, dispatch);
};

export const getSingleListingByListingHash = (listingHash) => async (dispatch, getState) => {
  const existingListings = getCurrentListings(getState()).listings;
  const found = existingListings.find((listing) => listing.listing_hash === listingHash);
  if (found) {
    return found;
  }

  const query = {
    listing_hash: listingHash,
    limit: 1,
  };

  const listings = await market.search(query);
  if (listings.length !== 1) {
    throw new Error('listing not found')
    return null;
  }
  return listings[0];
};
