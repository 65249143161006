import { getCart } from '../selectors/cart';
import { getCurrency } from '../selectors/currency';

export const addListingToCart = (listing) => ({
  type: 'APPEND_CART',
  payload: {
    listings: [listing],
  },
});

export const removeListingFromCard = (id) => (dispatch, getState) => {
  dispatch({
    type: 'REMOVE_FROM_CART',
    payload: {
      listingId: id,
    },
  });
  
  const cart = getCart(getState());
  if (cart.listings.length === 0) {
    dispatch({
      type: 'CHANGE_CART_VISIBILITY',
      payload: {
        visible: false,
      },
    });
  }
};

export const showCart = () => ({
  type: 'CHANGE_CART_VISIBILITY',
  payload: {
    visible: true,
  },
});

export const hideCart = () => ({
  type: 'CHANGE_CART_VISIBILITY',
  payload: {
    visible: false,
  },
});