import { createStore, applyMiddleware, compose, bindActionCreators } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers';
import _actions from './actions';
import CreateMonitors from './monitors';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composedEnhancer = composeEnhancers(applyMiddleware(thunkMiddleware));
export const store = createStore(rootReducer, composedEnhancer);

export const actions = bindActionCreators(
  _actions,
  store.dispatch,
); 

CreateMonitors(actions);
