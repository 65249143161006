import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Dialog, Popover, Tab, Transition,
} from '@headlessui/react';
import {
  MenuIcon, ShoppingCartIcon, XIcon,
} from '@heroicons/react/outline';
import getCategories from './store/selectors/get-featured-categories';
import CurrencyDropdown from './components/CurrencyDropdown';
import SearchBox from './components/SearchBox';
import { actions } from './store'; 
import { getCart } from './store/selectors/cart';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Nav({ categories, listings }) {
  const [open, setOpen] = useState(false);

  const onClickCategory = useCallback((category, close) => {
    actions.searchListing({
      category,
    });
    if (close) {
      close()
    }
  }, []);

  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              <Tab.Group as="div" className="mt-2">
                <div className="border-b border-gray-200">
                  <Tab.List className="-mb-px flex px-4 space-x-8">
                  <Tab
                        key={categories.name}
                        className={({ selected }) => classNames(
                          selected ? 'text-green-600 border-green-600' : 'text-gray-900 border-transparent',
                          'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium',
                        )}
                      >
                        {categories.name}
                      </Tab>
                  </Tab.List>
                </div>
                <Tab.Panels as={Fragment}>
                  {[categories].map((category) => (
                    <Tab.Panel key={category.name} className="pt-10 pb-8 px-4 space-y-10">
                      {category.categories.map((section) => (
                        <div key={section.name} className="border-b border-gray-200 py-5">
                          <Link to="/" onClick={() => onClickCategory(section.name, () => setOpen(false))} id={`${category.id}-${section.id}-heading-mobile`} className="font-medium text-gray-900">
                            {section.name}
                          </Link>
                          <ul
                            role="list"
                            aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                            className="mt-6 flex flex-col space-y-6"
                          >
                            {section.categories.map((item) => (
                              <li key={item.name} className="flow-root">
                                <Link to="/" onClick={() => onClickCategory(section.name, () => setOpen(false))} href={item.href} className="-m-2 p-2 block text-gray-500">
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>

            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <header className="relative bg-white z-10">
        <p className="bg-green-600 h-10 flex items-center justify-center text-sm font-medium text-white px-4 sm:px-6 lg:px-8">
         🎉 We officially launched particl.store! 🎉
        </p>

        <nav aria-label="Top" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200">
            <div className="h-16 flex items-center">
              <button
                type="button"
                className="bg-white p-2 rounded-md text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <Link to="/" onClick={() => actions.searchListing()}>
                  <span className="sr-only">Workflow</span>
                  <img
                    className="h-8 w-auto"
                    src="/logo.svg"
                    alt=""
                  />
                </Link>
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch w-full">
                <div className="h-full flex space-x-8">
                <Popover key={categories.name} className="flex">
                      {({ open, close }) => (
                        <>
                          <div className="relative flex">
                            <Popover.Button
                              className={classNames(
                                open
                                  ? 'border-green-600 text-green-600'
                                  : 'border-transparent text-gray-700 hover:text-gray-800',
                                'relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px',
                              )}
                            >
                              {categories.name}
                            </Popover.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Popover.Panel className="absolute top-full inset-x-0 text-sm text-gray-500">
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                              <div className="relative bg-white">
                                <div className="max-w-7xl mx-auto px-8">
                                  <div className="grid grid-cols-1 gap-y-10 gap-x-8 py-16">
                                    <div className="row-start-1 grid grid-cols-6 gap-y-10 gap-x-8 text-sm">
                                      {categories.categories?.map((subcategory) => (
                                        <div key={subcategory.name}>
                                          <Link to="/" id={`${subcategory.name}-heading`}  onClick={() => onClickCategory(subcategory.name, close)} className="font-medium text-gray-900">
                                            {subcategory.name}
                                          </Link>
                                          <ul
                                            role="list"
                                            aria-labelledby={`${subcategory.name}-heading`}
                                            className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                          >
                                            {subcategory.categories?.map((item) => (
                                              <li key={item.name} className="flex">
                                                <Link to="/" onClick={() => onClickCategory(item.name, close)} className="hover:text-gray-800">
                                                  {item.name}
                                                </Link>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  <SearchBox />
                </div>
              </Popover.Group>

              <div className="ml-auto flex items-center">
                {/* Search */}
                <div className="flex lg:ml-6 w-32">
                  <CurrencyDropdown />
                </div>

                {/* Cart */}
                <div className="ml-4 flow-root lg:ml-6">
                  <a href="#" className="group -m-2 p-2 flex items-center" onClick={() => actions.showCart()}>
                    <ShoppingCartIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{listings.length}</span>
                    <span className="sr-only">items in cart, view bag</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

Nav.propTypes = {
  listings: PropTypes.array,
  categories: PropTypes.object,
};

export default connect((state) => ({ ...getCategories(state), ...getCart(state) }))(Nav);
