import React from 'react';

export default function Checkout() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="flex flex-col items-center justify-center h-screen  text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block"> 🎉Thank you for your interest!🎉</span>
            <span className="block">But we&apos;re just testing the waters! 🌊</span>
            <span className="block">We may be adding a real checkout if there is enough interest. 💡</span>
            <img className="block" src="/soontm.jpeg" />
          </h2>
        </div>
      </div>
    )
  }