import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import './App.css';
import Listing from './Listing';
import Nav from './Nav';
import Overview from './Overview';
import Cart from './Cart';
import Checkout from './Checkout';

import { store } from './store';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Nav />
        <Routes>
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/listing/:id" element={<Listing />} />
          <Route path="/" element={<Overview />} />
        </Routes>
        <Cart />
      </Router>
    </Provider>

  );
}

export default App;
