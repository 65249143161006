import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import getCurrentListings from './store/selectors/get-current-listings';
import { actions } from './store';
import { getThumbail } from './utils';
import { getCurrency,getCurrencyExchange } from './store/selectors/currency';
import SearchBox from './components/SearchBox';

function Overview({ listings, isLastPage, currency, exchangeRates }) {
  const products = listings;
  const hasMore = !isLastPage;

  const convertPrice = useCallback(
    (price) =>
    // TODO: React Hook pls
      actions.convertPrice(price),
    [currency, exchangeRates, actions],
  );

  return (
    <div className="bg-white">
      <div className="w-full max-w-2xl mx-auto py-4 px-4 lg:hidden">
        <SearchBox />
      </div>
      <div className="max-w-2xl mx-auto py-10 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Products</h2>
        <InfiniteScroll
          dataLength={products.length} // This is important field to render the next data
          next={actions.scrollListings}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p className="mt-1 text-lg font-medium text-gray-900">You&apos;ve seen everything here! You will have to put up something for sale if you want to scroll beyond this point..</p>
          }
        >
          <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {products.map((product) => (
              <Link to={`/listing/${product.listing_hash}`} key={product.id} href={product.href} className="group">
                <div className="w-full aspect-w-1 aspect-h-1 cdbg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                  {/** no justify-center or grid */}
                  <img
                    src={getThumbail(product)}
                    alt={product.short_description}
                    className="w-full sm:h-64 object-center object-cover group-hover:opacity-75"
                  />
                  {/** className="h-full sm:h-64 object-center object-cover group-hover:opacity-75"  */}
                </div>
                <h3 className="mt-4 text-sm text-gray-700">{product.title}</h3>
                <p className="mt-1 text-lg font-medium text-gray-900">{convertPrice(product.price)}</p>
              </Link>
            ))}

          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

Overview.propTypes = {
  listings: PropTypes.array,
  isLastPage: PropTypes.bool,
  currency: PropTypes.string,
  exchangeRates: PropTypes.array, 
};

export default connect((state) => ({ ...getCurrentListings(state), ...getCurrency(state), ...getCurrencyExchange(state)}))(Overview);
