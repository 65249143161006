import { searchListing, scrollListings, getSingleListingByListingHash } from './search-listings';
import loadCategories from './categories';
import {
  addListingToCart, removeListingFromCard, showCart, hideCart,
} from './cart';
import {
  setCurrency, convertPrice, updateExchangeRates,
} from './currency';
const actions = {
  searchListing,
  scrollListings,
  getSingleListingByListingHash,
  loadCategories,
  setCurrency,
  addListingToCart,
  removeListingFromCard,
  convertPrice,
  showCart,
  hideCart,
  updateExchangeRates,
};

export default actions;
