const CreateListingsMonitor = async (actions) => {
  // TODO: get featured categories
  // TODO: get featured listings

  // On creation, search with the default query to ensure
  // that the overview has a page of listings ready to view.
  await actions.searchListing();
  await actions.loadCategories();
};
export default CreateListingsMonitor;
