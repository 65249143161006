import { combineReducers } from 'redux';
import { listingsReducer } from './listings';
import { categoriesReducer } from './categories';
import { cartReducer } from './cart';
import { currencyReducer } from './currency';

export default combineReducers({
  listings: listingsReducer,
  categories: categoriesReducer,
  cart: cartReducer,
  currency: currencyReducer,
});
