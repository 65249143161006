const CreateExchangeRateMonitor = async (actions) => {
  let timer = 30000
  while (true) {
    try {
      const result = await actions.updateExchangeRates()
      timer = (result[0].TimeToNextUpdate + 10) * 1000
    } catch(e) {
      console.error(e)
    }
    await sleep(timer) ;
  }
};
export default CreateExchangeRateMonitor;

function sleep(time){
  return new Promise((resolve)=>setTimeout(resolve,time)
)}