class CurrencyExchangeAPI {
  constructor() {
    this.rpcUrl = 'https://particl.store/exchange/rates';
  }

  generic = async () => {
    const result = fetch(this.rpcUrl, {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      referrerPolicy: 'no-referrer-when-downgrade',
      method: 'GET',
      mode: 'cors',
    });
    return result;
  };
  
    getExchange = async () => { 
      const response = await this.generic();
      return response.json();
    };
  }
  const exchangeRates = new CurrencyExchangeAPI();
  export default exchangeRates;