import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SearchIcon } from '@heroicons/react/outline';

import { actions } from '../store';
import { getSearchQuery } from '../store/selectors/get-search-query';

function SearchBox({ text }) {
  const onChangeSearchQuery = useCallback((event) => {
    const text = event.target.value || '';
    actions.searchListing({
      text,
    });
  }, []);

  return (
    <div className="pt-3 relative mx-auto text-gray-600">
      <input
        className="w-full border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        type="text"
        name="search"
        placeholder="Search"
        onChange={onChangeSearchQuery}
        value={text}
      />
      <button type="submit" className="absolute right-0 top-1 mt-5 mr-4">
        <SearchIcon className="text-gray-600 h-4 w-4" aria-hidden="true" />
      </button>
    </div>
  );
}

SearchBox.propTypes = {
  text: PropTypes.string,
};

export default connect(getSearchQuery)(SearchBox);
