class MarketAPI {
  constructor() {
    this.rpcUrl = 'https://particl.store/api/';
  }

  generic = async (path, method, request) => {
    const result = fetch(this.rpcUrl + path, {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      referrerPolicy: 'no-referrer-when-downgrade',
      body: request ? JSON.stringify(request) : null,
      method: method,
      mode: 'cors',
    });
    return result;
  };

  search = async (query) => {
    const response = await this.generic('search',  'POST', query);
    return response.json();
  };

  categories = async () => {
    const response = await this.generic('categories',  'GET');
    return response.json();
  };
}
//create currency folder or add getexchange rates
const market = new MarketAPI();
export default market;
