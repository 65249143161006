import { getDefaultCurrency } from '../selectors/currency';

const defaultCurrency = getDefaultCurrency();
const init = {
  currency: defaultCurrency,
  exchangeRates: [], 
};

export const currencyReducer = function (state = init, { type, payload }) {
  switch (type) {
    case 'UPDATE_EXCHANGES_RATES':
      return { ...state, exchangeRates: payload.exchangeRates };
    case 'SET_CURRENCY':
      return { ...state, currency: payload.currency };
    default:
      return state;
  }
};
