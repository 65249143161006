export const generateImageUrl = (imageHash) => {
  if (!imageHash) {
    return 'https://particl.store/tumbleweed.jpg';
  }
  return `https://particl.store/api/img/${imageHash}`;
};

export const getThumbail = (listing) => {
  let imageHash = null;
  if (Array.isArray(listing?.image_hashes) && listing.image_hashes.length > 0) {
    [imageHash] = listing.image_hashes;
  }
  return generateImageUrl(imageHash);
};
