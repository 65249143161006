import { getDefaultCurrency } from '../selectors/currency';
import { getDefaultCartVisibility } from '../selectors/cart';

const defaultCurrency = getDefaultCurrency();
const init = {
  visible: getDefaultCartVisibility(),
  listings: [],
  totalPrice: 0,
};

export const cartReducer = function (state = init, { type, payload }) {
  let newListings = [];
  let newTotal = 0;
  switch (type) {
    case 'SET_CART':
      return { ...state, listings: payload.listings };
    case 'APPEND_CART':
      const { listings } = payload;
      newListings = [...state.listings, ...listings];
      newTotal = newListings.reduce((prev, cur) => prev + cur.price, 0);
      return { ...state, listings: newListings, totalPrice: newTotal };
    case 'REMOVE_FROM_CART':
      const { listingId } = payload;
      newListings = state.listings.filter((listing) => listing.id !== listingId);
      newTotal = newListings.reduce((prev, cur) => prev + cur.price, 0);
      return { ...state, listings: newListings, total: newTotal };
    case 'CLEAR_CART':
      return { ...state, listings: [] };
    case 'CHANGE_CART_VISIBILITY':
      return { ...state, visible: payload.visible };
    default:
      return state;
  }
};
