import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { actions } from './store';
import { getCurrency } from './store/selectors/currency';
import MetaTag from './components/MetaTag';
import ImageGallery from './components/ImageGallery';

const product = {
  breadcrumbs: [
    { id: 1, name: 'Men', href: '#' },
    { id: 2, name: 'Clothing', href: '#' },
  ],
  images: [
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-secondary-product-shot.jpg',
      alt: 'Two each of gray, white, and black shirts laying flat.',
    },
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-01.jpg',
      alt: 'Model wearing plain black basic tee.',
    },
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-02.jpg',
      alt: 'Model wearing plain gray basic tee.',
    },
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-featured-product-shot.jpg',
      alt: 'Model wearing plain white basic tee.',
    },
  ],
};

function Listing({ currency }) {
  const [listing, setListing] = useState();
  const { id } = useParams();

  useEffect(() => {
    const load = async () => {
      const loaded = await actions.getSingleListingByListingHash(id)
      setListing(loaded);
    };
    load();
  }, []);

  const addToCart = useCallback(() => {
    actions.addListingToCart(listing);
    actions.showCart();
  }, [actions, listing]);

  const convertPrice = useCallback(
    (price) =>
    // TODO: React Hook pls
      actions.convertPrice(price),
    [currency, actions],
  );

  const onClickCategory = useCallback((category) => {
    actions.searchListing({
      category,
    });
  }, []);

  return (
    <div className="bg-white">
      <MetaTag listing={listing} />
      <div className="pt-6">
        <nav aria-label="Breadcrumb">
          <ol role="list" className="max-w-2xl mx-auto px-4 flex items-center space-x-2 sm:px-6 lg:max-w-7xl lg:px-8">
            {listing?.category.split(' > ').slice(1).map((category) => (
              <li key={category}>
                <div className="flex items-center">
                  <Link to="/" onClick={() => onClickCategory(category)} className="mr-2 text-sm font-medium text-gray-900">
                    {category}
                  </Link>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="w-4 h-5 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {listing?.title}
              </a>
            </li>
          </ol>
        </nav>

        <ImageGallery images={listing?.image_hashes} />

        {/* Product info */}
        <div className="max-w-2xl mx-auto pt-10 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:pt-16 lg:pb-24 lg:px-8 lg:grid lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">{listing?.title}</h1>
          </div>

          {/* Options */}
          <div className="mt-4 lg:mt-0 lg:row-span-3">
            <h2 className="sr-only">Product information</h2>
            <p className="text-3xl text-gray-900">{convertPrice(listing?.price)}</p>

            <form className="mt-10">
              <button
                type="button"
                className="mt-10 w-full bg-green-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => addToCart()}
              >
                Add to cart
              </button>
            </form>
          </div>

          <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            {/* Description and details */}
            <div>
              <h3 className="sr-only">Description</h3>

              <div className="space-y-6">
                <p className="text-base text-gray-900">{listing?.short_description}</p>
              </div>
            </div>

            <div className="mt-10">
              <h2 className="text-sm font-medium text-gray-900">Details</h2>

              <div className="mt-4 space-y-6">
                <p className="text-sm text-gray-600">{listing?.long_description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Listing.propTypes = {
  currency: PropTypes.string,
};
export default connect(getCurrency)(Listing);
