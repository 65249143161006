import { getCurrency, getCurrencyExchange } from '../selectors/currency';
import exchange from '../../api/currency-exchange';

  export const setCurrency = (currency) => ({
    type: 'SET_CURRENCY',
    payload: {
      currency,
    },
  });

  export const updateExchangeRates = () => async (dispatch) => {
    const exchangeRates = await exchange.getExchange();
    if (exchangeRates) {
      dispatch({
        type: 'UPDATE_EXCHANGES_RATES',
        payload: {
          exchangeRates
        }
      })
      return exchangeRates
    }
    return null
  };

  export const convertPrice = (price) => (dispatch, getState) => {
    const toCurrency = getCurrency(getState()).currency;
    const exchangeRates = getCurrencyExchange(getState()).exchangeRates;
    const rates = exchangeRates.find((exchangeRate) => exchangeRate.CurrencyName.includes(toCurrency));
    let rate = 1;  
    if (typeof rates !== 'undefined'){
      rate = rates.Avg;
    }
    const part = price / (10 ** 8);
    switch (toCurrency) {
      case 'USD':
        return `$${(part / rate).toFixed(2)}`;
      case 'EUR':
        return `€${(part / rate).toFixed(2)}`;
      case 'CAD':
        return `${(part / rate).toFixed(2)} $CAD`;
      case 'BTC': 
        return `₿${(part / rate).toFixed(8)} `;
      case 'PART':
        return `${part.toFixed(8)} PART`;
    }
  };