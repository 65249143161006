import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from '../store';
import { getAvailableCurrencies, getCurrency } from '../store/selectors/currency';

import Dropdown from './Dropdown';

function CurrencyDropdown({ currency }) {
  const currencies = getAvailableCurrencies();
  const setSelectedCurrency = useCallback((selectedCurrency) => {
    actions.setCurrency(selectedCurrency);
  });
  return (
    <Dropdown
      selectedIndex={currencies.indexOf(currency)}
      options={
        currencies.map((currency) => 
          {
           return {
             elem: (
              <a key={currency} className="text-gray-700 hover:text-gray-800 flex items-center">
                <img
                  src={`/currencies/${currency}.svg`}
                  alt=""
                  className="w-5 h-auto block flex-shrink-0"
                />
                <span className="ml-3 block text-sm font-medium">{currency}</span>
                <span className="sr-only">change currency</span>
              </a>
            ),
             onClick: () => setSelectedCurrency(currency)
           }

          
          })
      }
    />
  );
}

CurrencyDropdown.propTypes = {
  currency: PropTypes.string,
};

export default connect(getCurrency)(CurrencyDropdown);
