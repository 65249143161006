import market from '../../api/market';

export const loadCategories = () => async (dispatch) => {
  const categories = await market.categories();
  dispatch({
    type: 'SET_CATEGORIES',
    payload: categories,
  });
};

export default loadCategories;
