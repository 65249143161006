import { getDefaultQuery } from '../selectors/get-search-query';

const defaultQuery = getDefaultQuery();
const init = {
  featured: [],
  listings: [],
  isLastPage: false,
  query: defaultQuery,
};

export const listingsReducer = function (state = init, { type, payload }) {
  switch (type) {
    case 'SET_LISTINGS':
      return { ...state, listings: payload.listings, isLastPage: payload.isLastPage };
    case 'APPEND_LISTINGS':
      const { listings, isLastPage } = payload;
      return { ...state, listings: [...state.listings, ...listings], isLastPage };
    case 'SET_QUERY':
      const query = { ...defaultQuery, ...payload };
      return { ...state, query };
    default:
      return state;
  }
};
