import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { generateImageUrl } from '../utils';

function MetaTag({ listing }) {
  if (!listing) {
    return (<></>);
  }
  return (
    <Helmet>
      <title>{listing.title}</title>
      <meta name="title" content={listing.title} />
      <meta name="description" content={listing.short_description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://particl.market/listing/${listing.id}`} />
      <meta property="og:title" content={listing.title} />
      <meta property="og:description" content={listing.short_description} />
      <meta property="og:image" content={generateImageUrl(listing.image_hashes?.at(0))} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`https://particl.market/listing/${listing.id}`} />
      <meta property="twitter:title" content={listing.title} />
      <meta property="twitter:description" content={listing.short_description} />
      <meta property="twitter:image" content={generateImageUrl(listing.image_hashes?.at(0))} />
    </Helmet>
  );
}

MetaTag.propTypes = {
  listing: PropTypes.any,
};

export default MetaTag;
